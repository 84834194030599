import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'

const Page = ({ data, location }) => {
    const { bio, image_fanny, origin } = data.mdx.frontmatter

    return (
      <Layout location={location}>
        <SEO title="Genèse du projet" />
        <Container className="my-md-4 bg-green-light boder rounded text-justify">
          <Row>
            <Col className="mt-2">
              <ReactMarkdown parserOptions={{ commonmark: true }} source={origin} />
            </Col>
            <Col sm={8} md={6}>
              <div className="mt-2">
                <Img className="mx-auto d-block" alt="Fanny Grinbaud" fixed={image_fanny.childImageSharp.fixed} height="350px"/>
                  <p className="mt-2">
                  <ReactMarkdown parserOptions={{ commonmark: true }} source={bio} />
                  </p>
                </div>
            </Col>
          </Row>


        </Container>
        

      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
          frontmatter {
            origin
            bio
            image_fanny {
              childImageSharp {
                fixed(height: 350) {
                    ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
    }`
